import React from 'react';
import { graphql } from 'gatsby';
import { Box } from '@mui/material';

import TitledCard from '../atoms/titled-card';
import { SnsShare } from '../molecules/sns-share';
import { Article } from '../organisms/article';
import Layout from '../organisms/layout';
import { TableOfContents } from '../organisms/table-of-contents';
import TagCloud from '../organisms/tag-cloud';
import { PortfolioTemplateQuery } from '../../../types/graphql-types';
import PathUtils from '../../utils/path-utils';

export const query = graphql`
  query PortfolioTemplate($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        thumb {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
const PortfolioTemplate = (props: { data: PortfolioTemplateQuery }) => {
  const post = props.data.markdownRemark!;
  return (
    <Layout
      asides={[
        <TitledCard title="タグクラウド" key="tag-cloud">
          <TagCloud />
        </TitledCard>,
      ]}
      asidesSticky={[
        <TitledCard title="目次" key="Table-of-contents">
          <TableOfContents slug={post.fields?.slug ?? ''} />
        </TitledCard>,
        <SnsShare
          slug={PathUtils.slugToDateName(post.fields?.slug ?? '').name}
          title={post.frontmatter?.title ?? ''}
          excerpt={post?.excerpt ?? ''}
          key="sns"
        />,
      ]}
    >
      {/* portfolio.md で使用しているクラスのスタイルを定義する */}

      <Box
        sx={{
          '& .profile': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'stretch',
            columnGap: 5,
            rowGap: 3,
            my: 4,
          },

          '& .avatar': {
            m: 0,
          },

          '& .info': {
            m: 0,
            '& p': { my: 0 },
            '& hr': { mt: 1, mb: 2 },
          },

          '& .skills': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'stretch',
            alignContent: 'stretch',
            gap: 4,

            '& .card-box': { flexGrow: 1 },
            '& .card-box p': { m: 0 },
          },

          '& .acheve': {
            display: 'grid',
            gap: 4,
          },

          '& .product': {
            display: 'grid',
            gap: 4,
          },
        }}
      >
        <Article slug={post.fields?.slug ?? ''} />
      </Box>
    </Layout>
  );
};
export default PortfolioTemplate;
